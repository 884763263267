import React from "react";

const Footer = ({ lang }) => {

  const setLanguage = (selectedLang) => {
    localStorage.setItem("lang", selectedLang);
    window.location.reload();
  };

  return (
    <footer className="w-full bg-pink">
      <hr className="w-4/5 mx-auto border-t-2 border-white" />
      <div className="p-4 md:p-10">
        <div className="flex flex-col text-center mt-4 md:mt-0">
          <p className="underline">
            {lang === "en" ? (
              "Working Hours:"
            ) : lang === "de" ? (
              "Arbeitszeit:"
            ) : (
              "Çalışma Saatleri:"
            )}
          </p>
          <p>              
            {lang === "en" ? (
              "Monday-Friday"
            ) : lang === "de" ? (
              "Montag-Freitag"
            ) : (
              "Pazartesi-Cuma"
            )}: 08:30-19:00</p>
          <p>              
            {lang === "en" ? (
              "Saturday"
            ) : lang === "de" ? (
              "Samstag"
            ) : (
              "Cumartesi"
            )}: 08:30-18:00</p>
        </div>
      </div>
      <hr className="w-3/5 mx-auto border-t-2 border-white" />
      <div className="p-4 md:p-2 text-center md:text-left">
        <div className="flex justify-center w-fit m-auto">
          <button className="link_primary px-8 hover:bg-white_bg w-full" onClick={() => setLanguage("tr")}>TR</button>
          <button className="border-x px-8 link_primary hover:bg-white_bg w-full" onClick={() => setLanguage("en")}>EN</button>
          <button className="link_primary px-8 hover:bg-white_bg w-full" onClick={() => setLanguage("de")}>DE</button>
        </div>
        <p className="text-center p-2">Copyright © 2023 Deniz Yıldızı. All rights reserved</p>
      </div>
    </footer>
  );
};

export default Footer;