import React, { useState} from "react";
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './FirstHomeContainer.css';
import photo1 from "../../img/denizyıldızı3276-4.webp";
import photo2 from "../../img/denizyıldızı3292-2.webp";
import photo3 from "../../img/denizyıldızı3293-4.webp";
import photo4 from "../../img/denizyıldızı3295-2.webp";
import photo5 from "../../img/denizyıldızı3298-3.webp";
import photo6 from "../../img/denizyıldızı3299-2.webp";
import photo7 from "../../img/denizyıldızı3301-3.webp";
import photo8 from "../../img/denizyıldızı3305-2.webp";
import photo9 from "../../img/denizyıldızı3306-2.webp";
import photo10 from "../../img/denizyıldızı3307-2.webp";

const FirstHomeContainer = ( { lang }) => {

  const [modelId, setmodelId] = useState("");

  return (
    <div>
      <div className="flex mx-2 items-center flex-wrap py-2 mb-8 px-2 md:flex-nowrap md:px-16 ">
        <Carousel
          autoPlay
          infiniteLoop 
          centerMode 
          showArrows={false} 
          dynamicHeight={false} 
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          centerSlidePercentage={80}
          transitionTime={700}>
          <img src={photo1} alt="Slide 1"/>
          <img src={photo2} alt="Slide 2"/>
          <img src={photo3} alt="Slide 3"/>
          <img src={photo4} alt="Slide 4"/>
          <img src={photo5} alt="Slide 5"/>
          <img src={photo6} alt="Slide 6"/>
          <img src={photo7} alt="Slide 7"/>
          <img src={photo8} alt="Slide 8"/>
          <img src={photo9} alt="Slide 9" />
          <img src={photo10} alt="Slide 10"/>
        </Carousel>
        {/* Text */}
        <div className="flex flex-col justify-center items-center text-center mx-auto">
            <p className="text-xl m-5 md:text-3xl">
              {lang === "en" ? (
                "Welcome to our bridal collection, where your dreams become a reality."
              ) : lang === "de" ? (
                "Wir begleiten Sie auf Ihrer Reise, Ihr perfektes Brautkleid zu finden. Herzlich willkommen!"
              ) : (
                "Hayalinizdeki o anı gerçeğe dönüştürmeye hazırız."
              )}
            </p>
          <Link to="/collections">
            <button className="button_primary" >
              {lang === "en" ? (
                "Our Collection"
              ) : lang === "de" ? (
                "Unsere Kollektion"
              ) : (
                "Koleksiyonumuz"
              )}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FirstHomeContainer;