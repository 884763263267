import React, { useState} from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";

const Collections = ({ images }) => {
  
  const [index, setIndex] = useState(-1);
  const [title, setTitle] = useState("");

  const photos = images.map((image) => ({
    src: image.image,
    width: 400, height: 533
  }));

  return (
    <div className="bg-white_bg">
      <div className="p-4 mx-2 items-center">
        <PhotoAlbum layout="masonry" photos={photos}
          columns={(containerWidth) => {
            if (containerWidth < 600) return 2;
            if (containerWidth < 900) return 3;
            if (containerWidth < 1200) return 4;
            return 5;
          }}
          onClick={({ index }) => setIndex(index)} />
          <Lightbox
            slides={photos}
            open={index >= 0}
            index={index}
            close={() => setIndex(-1)}
            plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
          />
        </div>
    </div>
  );
};

export default Collections;
