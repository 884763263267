import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import axios from "axios";
import "./App.css";
import Header from "./components/layouts/Header";
import Footer from "./components/layouts/Footer";
import Home from "./components/Home/Home";
import AboutUs from "./components/AboutUs/AboutUs";
import Contact from "./components/Contact/Contact";
import AboutUsAdmin from "./components/AdminComponents/AboutUsAdmin";
import Collections from "./components/Collections/Collections";
import CollectionsAdmin from "./components/AdminComponents/CollectionsAdmin";
import Login from "./components/AdminComponents/Login";
import PrivateRoutes from "./components/AdminComponents/PrivateRoutes";

function App() {

  const [images, setImages] = useState([]);
  useEffect(() => {
    axios
      .get("https://dy-app-6ad047cffadf.herokuapp.com/images/")
      .then((res) => setImages(res.data))
      .catch((err) => console.log(err));
  }, []);

  const isAuth = localStorage.getItem('admin');
  const defLang = localStorage.getItem('lang');
  const lang = defLang || navigator.language || navigator.userLanguage;


  return (
    <div className="App">
      <Header lang={lang} />
      <Routes>
        <Route path="/" element={<Home lang={lang} images={images} />} />
        <Route path="/aboutus" element={<AboutUs lang={lang} />} />
        <Route path="/collections" element={<Collections images={images} />} />
        <Route path="/contact" element={<Contact lang={lang} />} />
        <Route element={<PrivateRoutes isAuth={isAuth} />}>
          <Route path="/collections/admin" element={<CollectionsAdmin images={images}/>}/>
          <Route path="/aboutus/admin" element={<AboutUsAdmin />}/>
        </Route>
        <Route path="/login" element={<Login isAuth={isAuth} lang={lang} />} />
      </Routes>
      <Footer lang={lang} />
    </div>
  );
}

export default App;
