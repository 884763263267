import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = ({ isAuth }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  let changeLeft = 3;

  const handleLogin = () => {
    if (username === 'denizyildizimoda35' && password === 'denizyildizimoda35+') {
      localStorage.setItem('admin', true);
    } else {
      if (changeLeft !== 0) {
        alert(`You have ${changeLeft} try left.`);
        changeLeft--;
      } else {
        alert("You've exhausted all your attempts.");
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem('admin')) {
      navigate('/collections/admin');
    }
  }, [navigate]);

  if (isAuth) {
    navigate('/');
  }

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-gray_bg">
        <div className="rounded border-2 py-2 px-16 w-min -mt-64 bg-white_bg flex flex-col align-middle justify-center items-center">
            <h2 className="font-bold">Login</h2>
            <div className="my-4">
                <label>Username:</label>
                <input
                    className="border-2 rounded-lg py-1 px-2 bg-white"
                    type="text"
                    value={username}
                    onChange={e => setUsername(e.target.value)} />
            </div>
            <div className="my-4">
                <label>Password:</label>
                <input
                    className="border-2 rounded-lg py-1 px-2 bg-white"
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)} />
            </div>
            <button className="bg-red hover:bg-lightred text-white font-semibold py-1 px-4 rounded shadow" onClick={handleLogin}>
            Login
            </button>
        </div>
    </div>
  );
};

export default Login;
