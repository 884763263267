import React from "react";
import { Link } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import './FirstHomeContainer.css';
import photo1 from "../../img/second_1.webp";
import photo2 from "../../img/second_2.webp";
import photo3 from "../../img/second_3.webp";
import photo4 from "../../img/second_4.webp";
import photo5 from "../../img/second_5.webp";
import photo6 from "../../img/second_6.webp";
import photo7 from "../../img/second_7.webp";
import photo8 from "../../img/second_8.webp";
import photo9 from "../../img/second_9.webp";
import photo10 from "../../img/second_10.webp";
import photo11 from "../../img/second_11.webp";
import photo12 from "../../img/second_12.webp";
import photo13 from "../../img/second_13.webp";
import photo14 from "../../img/second_14.webp";
import photo15 from "../../img/second_15.webp";

const SecondHomeContainer = ({ lang }) => {

  const desktop = window.innerWidth > 768;

  return (
    <div className="flex mx-2 items-center flex-wrap py-2 mb-8 px-2 md:flex-nowrap md:px-16">
      {desktop ? (
        <div className="flex flex-col justify-center items-center text-center mx-auto">
          <p className="text-xl m-5 md:text-3xl">
            {lang === "en" ? (
              "Find your perfect dress with us and let us make your special day unforgettable."
            ) : lang === "de" ? ( 
              "Finden Sie Ihr perfektes Brautkleid bei uns und lassen Sie uns Ihren besonderen Tag unvergesslich machen."
            ) : (
              "Birlikte en özel anınızı tasarlamaya başlayalım."
            )}
          </p>
          <Link to="/contact">
            <button className="bg-red text-white hover:bg-lightred hover:text-gray_bg py-3 px-6 md:py-4 md:px-8 rounded-lg md:text-lg transition-all duration-300 ease-in-out">
              {lang === "en" ? ( 
                "Contact With Us"
              ) : lang === "de" ? (
                "Kontaktieren Sie Uns"
              ) : (
                "Bize Ulaşın"
              )}
            </button>
          </Link>
        </div>
      ) : (
        null
      )}
      <Carousel
        autoPlay
        infiniteLoop 
        centerMode 
        showArrows={false} 
        dynamicHeight={false} 
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
        centerSlidePercentage={60}
        transitionTime={700}>
        <img src={photo1} alt="Slide 1" />
        <img src={photo2} alt="Slide 2" />
        <img src={photo3} alt="Slide 3" />
        <img src={photo4} alt="Slide 4" />
        <img src={photo5} alt="Slide 5" />
        <img src={photo6} alt="Slide 6" />
        <img src={photo7} alt="Slide 7" />
        <img src={photo8} alt="Slide 8" />
        <img src={photo9} alt="Slide 9" />
        <img src={photo10} alt="Slide 10" />
        <img src={photo11} alt="Slide 11" />
        <img src={photo12} alt="Slide 12" />
        <img src={photo13} alt="Slide 13" />
        <img src={photo14} alt="Slide 14" />
        <img src={photo15} alt="Slide 15" />
      </Carousel>
      {!desktop ? (
        <div className="flex flex-col justify-center items-center text-center mx-auto">
          <p className="text-xl m-5 md:text-4xl">
          {lang === "en" ? (
              "Find your perfect dress with us and let us make your special day unforgettable."
            ) : lang === "de" ? ( 
              "Finden Sie Ihr perfektes Brautkleid bei uns und lassen Sie uns Ihren besonderen Tag unvergesslich machen."
            ) : (
              "En özel gününüzü birlikte tasarlasayalım."
            )}
          </p>
          <Link to="/contact">
            <button className="bg-red text-white py-3 px-6 md:py-4 md:px-8 rounded-lg md:text-lg">
            {lang === "en" ? ( 
                "Contact With Us"
              ) : lang === "de" ? (
                "Kontaktieren Sie Uns"
              ) : (
                "Bizimle İletişime Geçin"
              )}
            </button>
          </Link>
        </div>
      ) : (
        null
      )}
    </div>
  );
};

export default SecondHomeContainer;