import React, { useState, useEffect } from "react";
import axios from "axios";

const AboutUsAdmin = () => {

const [about, setAbout] = useState([]);
useEffect(() => {
  axios
  .get("https://dy-app-6ad047cffadf.herokuapp.com/about-us/")
  .then((res) => setAbout(res.data))
  .catch((err) => console.log(err));
}, [])

const handleAboutChange = (event) => {
  setAbout(event.target.value);
};

const handleSave = () => {
  axios.put("https://dy-app-6ad047cffadf.herokuapp.com/about-us", {
    text: about
  })
  .then((res) => console.log("Saved successfully"))
  .catch((err) => console.log(err));
};

  const text = about.text;
  return (
    <div className="h-screen bg-gray_bg px-4 md:px-32">
      <div className="h-full mx-auto px-4 md:px-16 pt-8 bg-white_bg">
        <h1 className="underline font-bold text-xl">About Us</h1>
        <textarea
          className="w-full h-2/3 md:h-1/2 p-2 my-2 font-sans"
          value={text}
          onChange={handleAboutChange}
        />
        <button
          onClick={handleSave}
          className="bg-red hover:bg-lightred text-white font-semibold py-2 px-4 rounded shadow"
        >
          Save
        </button>
      </div>
    </div>
  );
  
};

export default AboutUsAdmin;