import React from "react";


function Contact ({ lang }) {

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, email, message } = e.target.elements;
        let details = {
          name: name.value,
          email: email.value,
          message: message.value,
        };
        let response = await fetch("https://dy-app-6ad047cffadf.herokuapp.com/contact", {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(details),
          });
          let result = await response.json();
          alert(result.status);
    };

    return (
        <div className="flex text-base flex-col md:flex-row justify-between flex-wrap bg-gray_bg ">
            <div className="m-4 flex flex-col flex-1">
                <h3 className="font-bold text-lg">
                {lang === "en" ? (
                    "Phone:"
                ) : lang === "de" ? (
                    "Telefon:"
                ) : (
                    "Telefon:"
                )}
                </h3>
                <a href="tel:+90 232 489 74 76" target="_blank" rel="noreferrer">+90 232 489 74 76</a>
                <h3 className="font-bold text-lg mt-4">Whatsapp:</h3>
                <a href="https://wa.me/905343581488" target="_blank" rel="noreferrer">+90 534 358 14 88</a>
                <h3 className="font-bold text-lg mt-4">Email:</h3>
                <a href="mailto:info@denizyildizimodaevi.com" target="_blank" rel="noreferrer">info@denizyildizimodaevi.com</a>
                <h3 className="font-bold text-lg mt-4">
                {lang === "en" ? (
                    "Adress:"
                ) : lang === "de" ? (
                    "Adresse:"
                ) : (
                    "Adres:"
                )}
                </h3>
                <p className="whitespace-normal">Necatibey Bulvari Akdeniz Mahallesi, Ca No: 31/B Konak/Izmir/Turkey</p>
                <iframe
                    title="GoogleMaps"
                    className="mt-2 md:w-600 md:h-450"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3125.8059571354656!2d27.131524415336376!3d38.42284897964661!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bbd8fb0231019b%3A0xaa92894fcc3bb725!2zRGVuaXogWcSxbGTEsXrEsSBNb2Rh!5e0!3m2!1sde!2sde!4v1645831318236!5m2!1sde!2sde"
                    width="100%"
                    height="450"
                    loading="lazy">
                </iframe>
            </div>
            <form className="flex flex-col flex-1 min-w-300 bg-gray_bg p-4" onSubmit={handleSubmit}>
                <h3 className="font-bold text-lg underline">
                {lang === "en" ? (
                    "Write Us"
                ) : lang === "de" ? (
                    "Schreiben Sie Uns"
                ) : (
                    "Bize Ulaşın"
                )}
                </h3>
                <div className="flex flex-col flex-1 min-w-300 bg-gray_bg p-4 border">
                    <div className="flex flex-col">
                        <label htmlFor="name">
                        {lang === "en" ? (
                            "Name:"
                        ) : lang === "de" ? (
                            "Name:"
                        ) : (
                            "İsim:"
                        )}
                        </label>
                        <input className="w-4/5 p-2 border box-border mt-2 mb-4 bg-white rounded-md" type="text" id="name" required />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="email">Email:</label>
                        <input className="w-4/5 p-2 border box-border mt-2 mb-4 bg-white rounded-md" type="email" id="email" required />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="message">
                        {lang === "en" ? (
                            "Message:"
                        ) : lang === "de" ? (
                            "Nachricht:"
                        ) : (
                            "Mesaj:"
                        )}
                        </label>
                        <textarea className="w-4/5 p-2 border box-border mt-2 mb-4 bg-white rounded-md" id="message" required />
                    </div>
                    <button className="bg-red text-white hover:bg-lightred hover:text-gray_bg w-fit h-min py-2 px-6 rounded-lg md:text-lg transition-all duration-300 ease-in-out">
                    {lang === "en" ? (
                        "Send"
                    ) : lang === "de" ? (
                        "Senden"
                    ) : (
                        "Gönder"
                    )}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default Contact;