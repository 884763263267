import React, { useState } from "react";
import axios from "axios";
import FormData from "form-data";

const CollectionsAdmin = ({ images }) => {
    const [file, setFile] = useState(null);
    const [modelID, setModelID] = useState("");
    const [year, setYear] = useState("");
    const [error, setError] = useState(null);

    const handleDelete = (imageId) => {
        axios
        .delete(`https://dy-app-6ad047cffadf.herokuapp.com/images/${imageId}`)
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
          setError("An error occurred while deleting the image.");
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    
        if (file) {
          const formData = new FormData();
          formData.append("image", file);
          formData.append("modelID", modelID);
          formData.append("year", year);
    
          axios
            .post("https://dy-app-6ad047cffadf.herokuapp.com/images/add", formData)
            .then((res) => {
              console.log(res.data);
              setFile(null);
              setModelID(" ");
              setYear(" ");
            })
            .catch((err) => {
              console.log(modelID + " " + year + " " + file);
              console.log(err);
              setError("An error occurred while uploading the image.");
            });
        }
      };

      const handleChange = (event) => {
        const selectedFile = event.target.files[0];
        const allowedTypes = ["image/webp"];
        if (selectedFile && allowedTypes.includes(selectedFile.type)) {
          setFile(selectedFile);
          setError(null);
        } else {
          setFile(null);
          setError("Please select a valid WEBPimage file.");
        }
      };

      return (
        <div className="bg-white_bg">
          <div className="p-10 mx-2 items-center">
            <form onSubmit={handleSubmit} encType="multipart/form-data" className="flex flex-col items-">
              <div className="flex flex-col w-full max-w-sm">
                <label className="mt-2">
                  Model:
                  <input
                    type="text"
                    value={modelID}
                    onChange={(e) => setModelID(e.target.value)}
                    className="border-2 rounded-lg p-2 mt-1"
                  />
                  </label>
                  <label className="mt-2">
                    Year:
                    <input
                      type="text"
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                      className="border-2 rounded-lg p-2 mt-1"
                    />
                  </label>
                  <label className="mt-2">
                    Upload File:
                    <input type="file" onChange={handleChange} className="mt-1" />
                  </label>
                  {error && <p className="text-red-500 mt-2">{error}</p>}
                  <button
                    type="submit"
                    className="bg-red hover:bg-lightred text-white font-semibold py-1 px-4 w-min rounded shadow mt-4"
                  >
                    Upload
                  </button>
                </div>
                </form>

                <div className="p-8 grid w-fit grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-5">
                    {images.map((image) => (
                        <div key={image._id} className="relative border-2 border-white">
                            <img
                                src={image.image}
                                alt="uploaded"
                            />
                            <div className="absolute bottom-0 left-0 right-0 flex justify-center items-center bg-white py-1">
                              <button
                                className="bg-red hover:bg-lightred text-white font-semibold py-1 px-4 rounded shadow"
                                onClick={() => handleDelete(image._id)}>
                                  Delete
                              </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
      )
};

export default CollectionsAdmin;