import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

const ThirdHomeContainer = ({ lang }) => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getPosts = async () => {
      const accessToken =
        "IGQWRNcFU2QkFGYnI3WmJmWUsyQ2tyOUMybThZAVDF4ckliUGFKaGM3ODRwckJBX2ZAieFIyWjMtdElTVEJNTkYyN2dvYzFKTkpiejBwQmNUZA1N5V3NXRF9TNFdsbGpJVEtzUmc2dmRHbmxWcmdmdzJFS19pN1k1LWsZD";

      try {
        const response = await fetch(
          `https://graph.instagram.com/me/media?fields=id,caption,media_url,media_type&access_token=${accessToken}&limit=9`
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch Instagram posts: ${response.statusText}`);
        }

        const data = await response.json();
        setPosts(data.data);
      } catch (error) {
        setError(error.message);
      }
    };

    getPosts();
  }, []);

  const desktop = window.innerWidth > 768;
  const numPicturesToShow = desktop ? 6 : 9;

  return (
    <div className="flex flex-col items-center flex-wrap pb-8 md:flex-nowrap md:px-16">
      {error ? (
        <Link to="https://www.instagram.com/denizyildizi.moda/" target="_blank" rel="noopener noreferrer">
          <button className="button_primary">
            {lang === "en" ? (
              "Follow us on Instagram"
            ) : lang === "de" ? (
              "Folgen Sie uns auf Instagram"
            ) : (
              "Bizi Instagram'da takip edin"
            )}
          </button>
        </Link>
      ) : (
        <>
          {posts.length > 0 && (
            <div className="relative grid grid-cols-3 gap-2 md:w-1/2 bg-white border-8 border-white mx-4 my-8">
              {posts.slice(0, numPicturesToShow).map((post) => (
                <div key={post.id}>
                  <a href="https://www.instagram.com/denizyildizi.moda/" aria-label="Instagram Post" target="_blank" rel="noreferrer">
                    {post.media_type === "VIDEO" ? (
                      <video
                        muted
                        loop
                        playsInline
                        autoPlay
                        src={post.media_url}
                        alt="Instagram Post"
                        loading="lazy"
                        className="aspect-square object-cover bg-white"
                      />
                    ) : (
                      <img
                        src={post.media_url}
                        alt="Instagram Post"
                        className="aspect-square object-cover bg-white"
                        loading="lazy"
                      />
                    )}
                  </a>
                </div>
              ))}
            </div>
          )}
          <Link to="https://www.instagram.com/denizyildizi.moda/" target="_blank" rel="noopener noreferrer">
            <button className="button_primary">
              {lang === "en" ? (
                "Follow us on Instagram"
              ) : lang === "de" ? (
                "Folgen Sie uns auf Instagram"
              ) : (
                "Bizi Instagram'da takip edin"
              )}
            </button>
          </Link>
        </>
      )}
    </div>
  );
};

export default ThirdHomeContainer;
