import React from "react";
import love from "../../img/love.svg";
import instagram from "../../img/instagram.svg";
import FirstHomeContainer from "./FirstHomeContainer";
import SecondHomeContainer from "./SecondHomeContainer";
import ThirdHomeContainer from "./ThirdHomeContainer";

const Home = ({ lang, images }) => {
  return (
    <div className="bg-white_bg">
      <FirstHomeContainer lang={lang} images={images}/>
      <div className="flex flex-row items-center justify-center">
        <hr className="border-t-2 border-white w-2/5 mr-4" />
        <img className="w-16 h-16" src={love} alt="Img" />
        <hr className="border-t-2 border-white w-2/5 ml-4" />
      </div>
      <SecondHomeContainer lang={lang} images={images}/>
      <div className="Contact"></div>
      <div className="flex flex-row items-center justify-center">
        <hr className="border-t-2 border-white w-2/5 mr-4" />
        <a href="https://www.instagram.com/denizyildizi.moda/" target="_blank" rel="noopener noreferrer">
          <img className="w-16 h-16" src={instagram} alt="Instagram" />
        </a>
        <hr className="border-t-2 border-white w-2/5 ml-4" />
      </div>
      <div className="Instagram"></div>
      <ThirdHomeContainer lang={lang} />
    </div>
  );
};

export default Home;