import React, { useState, useEffect } from "react";
import axios from "axios";
import video from "../../img/abtVideo.mp4";
import {Translator, Translate} from 'react-auto-translate';

function AboutUs( { lang }) {
  const [about, setAbout] = useState([]);

  useEffect(() => {
    axios
      .get("https://dy-app-6ad047cffadf.herokuapp.com/about-us/")
      .then((res) => setAbout(res.data))
      .catch((err) => console.log(err));
  }, []);

  const text = about.text;

  return (

    <div className="h-screen bg-gray_bg flex flex-col md:flex-row">
      {window.innerWidth >= 768 ? (
        <video
          autoPlay
          muted
          loop
          src={video}
          alt="Instagram Post"
          className="h-full top-0"
        />
      ) : (
        <video
          autoPlay
          muted
          loop
          src={video}
          alt="Instagram Post"
          className="h-full m-0 p-0 absolute opacity-30 top-0 left-0 w-full"
        />
      )}
      <div className="h-full p-8 text-center bg-white_bg">
        <h1 className="underline font-bold text-2xl mb-2">
          {lang === "en" ? (
            "About Us"
          ) : lang === "de" ? (
            "Über Uns"
          ) : (
            "Hakkımızda"
          )}
        </h1>
        <Translator
          from='en'
          to={lang}
          googleApiKey='AIzaSyBiqIqmnceEsQ_0P3ubx_uvqErTrUQqkrg'>
          <p className="text-xl"><Translate>{text}</Translate></p>
        </Translator>
      </div>
      {window.innerWidth >= 768 ? (
        <video
          autoPlay
          muted
          loop
          src={video}
          alt="Instagram Post"
          className="h-full"
        />
      ) : null}
    </div>
  );
}

export default AboutUs;
