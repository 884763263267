import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = ({ lang }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="md:mb-20 mb-16">
      <div className="top-0 left-0 w-full md:h-20 h-16 bg-pink flex flex-row md:justify-evenly justify-between items-end pb-2 fixed z-50">
        <div className="w-fit flex justify-center">
          <Link to="/" className="title">DENİZ YILDIZI</Link>
        </div>
        <div className="hidden md:flex grid-cols-3 gap-2 space-x-8 font-bold text-md pb-1">
          <Link to="/aboutus" className="link_primary">
          {lang === "en" ? (
            "About Us"
          ) : lang === "de" ? (
            "Über Uns"
          ) : (
            "Hakkımızda"
          )}
          </Link>
          <Link to="/collections" className="link_primary">
          {lang === "en" ? (
            "Collection"
          ) : lang === "de" ? (
            "Kollektion"
          ) : (
            "Modeller"
          )}
          </Link>
          <Link to="/contact" className="link_primary">
          {lang === "en" ? (
            "Contact"
          ) : lang === "de" ? (
            "Kontakt"
          ) : (
            "İletişim"
          )}
          </Link>
        </div>
        <div className="md:hidden ml-4">
          <button type="submit" aria-label="Open Menu" onClick={toggleMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </div>
      </div>
      {menuOpen ? (
        <div className="md:hidden bg-pink fixed top-16 left-0 right-0 z-40 transition-all ease-in-out duration-300 transform translate-y-0">
          <div className="flex flex-col items-center mt-2">
            <hr className="border-t-2 border-white w-4/5"/>
            <Link to="/aboutus" onClick={toggleMenu} className="text-xl py-2 px-4 active:bg-red active:text-white">
            {lang === "en" ? (
            "About Us"
          ) : lang === "de" ? (
            "Über Uns"
          ) : (
            "Hakkımızda"
          )}
            </Link>
            <hr className="border-t-2 border-white w-4/5"/>
            <Link to="/collections" onClick={toggleMenu} className="text-xl py-2 px-4 active:bg-red active:text-white">
            {lang === "en" ? (
            "Collection"
          ) : lang === "de" ? (
            "Kollektion"
          ) : (
            "Modeller"
          )}
            </Link>
            <hr className="border-t-2 border-white w-4/5"/>
            <Link to="/contact" onClick={toggleMenu} className="text-xl py-2 px-4 active:bg-red active:text-white">
            {lang === "en" ? (
            "Contact"
          ) : lang === "de" ? (
            "Kontakt"
          ) : (
            "İletişim"
          )}
            </Link>
          </div>
          <hr className="border-t-2 border-white w-full"/>
        </div>
      ) : (
        <div className="md:hidden opacity-0 bg-pink fixed top-16 left-0 right-0 z-0 transition-all ease-in-out duration-300 transform translate-y-0" />
      )
      }
    </nav>
  );
};

export default Header;